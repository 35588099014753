/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsSolidHashtag = ({ className }) => {
  return (
    <svg
      className={`heroicons-solid-hashtag ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M11.0974 1.51459C11.5035 1.59582 11.767 1.99094 11.6857 2.39711L10.6651 7.50002H15.1351L16.2146 2.10294C16.2958 1.69677 16.6909 1.43335 17.0971 1.51459C17.5033 1.59582 17.7667 1.99094 17.6854 2.39711L16.6649 7.50002H20.25C20.6642 7.50002 21 7.83581 21 8.25002C21 8.66424 20.6642 9.00002 20.25 9.00002H16.3649L15.1649 15H18.75C19.1642 15 19.5 15.3358 19.5 15.75C19.5 16.1642 19.1642 16.5 18.75 16.5H14.8649L13.7854 21.8971C13.7042 22.3033 13.3091 22.5667 12.9029 22.4855C12.4967 22.4042 12.2333 22.0091 12.3146 21.6029L13.3351 16.5H8.86515L7.78573 21.8971C7.70449 22.3033 7.30938 22.5667 6.90321 22.4855C6.49704 22.4042 6.23362 22.0091 6.31486 21.6029L7.33544 16.5H3.75C3.33579 16.5 3 16.1642 3 15.75C3 15.3358 3.33579 15 3.75 15H7.63544L8.83544 9.00002H5.25C4.83579 9.00002 4.5 8.66424 4.5 8.25002C4.5 7.83581 4.83579 7.50002 5.25 7.50002H9.13544L10.2149 2.10294C10.2961 1.69677 10.6912 1.43335 11.0974 1.51459ZM10.3651 9.00002L9.16515 15H13.6351L14.8351 9.00002H10.3651Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
