/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniReceiptRefund = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-receipt-refund ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M4.93005 2.31046C6.5916 2.10551 8.28365 2 10 2C11.7163 2 13.4084 2.10551 15.07 2.31046C16.1942 2.44913 17 3.41374 17 4.51661V17.25C17 17.5078 16.8676 17.7475 16.6494 17.8848C16.4312 18.0221 16.1578 18.0377 15.9255 17.9261L13.125 16.5819L10.3245 17.9261C10.1194 18.0246 9.88061 18.0246 9.67545 17.9261L6.875 16.5819L4.07455 17.9261C3.84215 18.0377 3.56875 18.0221 3.35057 17.8848C3.13239 17.7475 3 17.5078 3 17.25V4.51661C3 3.41374 3.80579 2.44913 4.93005 2.31046ZM9.75172 6.30747C10.0596 6.03038 10.0846 5.55616 9.80747 5.24828C9.53038 4.94039 9.05616 4.91544 8.74828 5.19253L6.24828 7.44253C6.09024 7.58476 6 7.78738 6 8C6 8.21262 6.09024 8.41524 6.24828 8.55747L8.74828 10.8075C9.05616 11.0846 9.53038 11.0596 9.80747 10.7517C10.0846 10.4438 10.0596 9.96962 9.75172 9.69253L8.70447 8.75H10.625C11.6605 8.75 12.5 9.58947 12.5 10.625C12.5 11.6605 11.6605 12.5 10.625 12.5C10.2108 12.5 9.875 12.8358 9.875 13.25C9.875 13.6642 10.2108 14 10.625 14C12.489 14 14 12.489 14 10.625C14 8.76104 12.489 7.25 10.625 7.25H8.70447L9.75172 6.30747Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
