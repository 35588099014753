import { useTranslation } from 'react-i18next';
import { translations } from '../../locales';
import { BrFlag, UsFlag, MxFlag } from '../../flags';
import './style.css'
import { useEffect, useRef, useState } from 'react';
import { HeroiconsMiniChevronDown } from '../../icons';

export const LanguageSelect = () => {
  const { i18n } = useTranslation();
  const [showLanguages, setShowLanguages] = useState(false);
  const [openAnimation, setOpenAnimation] = useState(false);
  const [closeAnimation, setCloseAnimation] = useState(false);
  const dropdownRef = useRef(null)

  const flags = {
    'en-US': <UsFlag />,
    'pt-BR': <BrFlag />,
    'es-MX': <MxFlag />
  };

  const languagesExceptCurrent = Object.keys(translations).filter((language) => language !== i18n.language);

  const handleHideLanguages = () => {
    setOpenAnimation(false)
    setCloseAnimation(true)
    setTimeout(() => {
      setShowLanguages(false)
    }, 301)
  }

  const handleShowLanguages = () => {
    setCloseAnimation(false)
    setShowLanguages(true)
    setOpenAnimation(true)
  }

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    document.documentElement.lang = i18n.language.split('-')[0];
    handleHideLanguages()
  }

  const handleToggleLanguages = () => {
    if (showLanguages) {
      handleHideLanguages()
    } else {
      handleShowLanguages()
    }
  }

  const handleClick = (e) => {
    if (!dropdownRef.current.contains(e.target) && showLanguages) {
      handleHideLanguages()
    }
  }

  useEffect(() => {
    document.documentElement.lang = i18n.language.split('-')[0];
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  return (
    <div className="language-select" ref={dropdownRef}>
      <button
        type='button'
        onClick={handleToggleLanguages}
      > 
        {flags[i18n.language]}
        <span>{i18n.language}</span>
        <div className='chevron'>
          <HeroiconsMiniChevronDown />
        </div>
      </button>
      <div className={`dropdown ${openAnimation ? 'open-dropdown' : ''}${closeAnimation ? 'close-dropdown' : ''}`}>
      {showLanguages && languagesExceptCurrent.map((language) => (
        <button
          key={language}
          type='button'
          onClick={() => handleLanguageChange(language)}
        >
          {flags[language]}
          <span>{language}</span>
        </button>   
      ))}
      </div>
    </div>
  );
}