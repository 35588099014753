/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniHomeModern = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-home-modern ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M14.9156 2.40402C15.1067 2.77152 14.9637 3.22435 14.5962 3.41545L14 3.72545V17C14 17.5523 13.5523 18 13 18H10.74C10.3258 18 9.99 17.6642 9.99 17.25V13.75C9.99 13.3358 9.65421 13 9.24 13H6.75C6.33579 13 6 13.3358 6 13.75V17.25C6 17.6642 5.66421 18 5.25 18H1.75C1.33579 18 1 17.6642 1 17.25C1 16.8358 1.33579 16.5 1.75 16.5H2V9.95729C1.65296 10.0804 1.25946 9.93204 1.08474 9.59605C0.893644 9.22855 1.03664 8.77572 1.40414 8.58462L2 8.27477V5.75003C2 5.33582 2.33579 5.00003 2.75 5.00003C3.16421 5.00003 3.5 5.33582 3.5 5.75003V7.49477L13.9041 2.08462C14.2716 1.89352 14.7245 2.03652 14.9156 2.40402Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M15.8615 8.56926C16.0854 8.43328 16.3642 8.42407 16.5967 8.54496L18.596 9.58462C18.9635 9.77572 19.1065 10.2286 18.9154 10.5961C18.7407 10.932 18.3473 11.0803 18.0003 10.9574V16.5H18.25C18.6642 16.5 19 16.8358 19 17.25C19 17.6642 18.6642 18 18.25 18H16.25C16.0511 18 15.8603 17.921 15.7196 17.7803C15.579 17.6397 15.5 17.4489 15.5 17.25L15.5006 9.21031C15.5007 8.94827 15.6375 8.70524 15.8615 8.56926Z"
        fill="#7041F1"
      />
    </svg>
  );
};
