import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { LanguageSelect } from '../../components/LanguageSelect/LanguageSelect';
import { Logo } from '../../components/Logo';
import { useTheme } from '../../hooks/useTheme';
import './style.css'

export const SuccessPage = () => {
  const { t } = useTranslation('index');
  const { theme, ThemeToggleButton } = useTheme();
  const location = useLocation()
  const navigate = useNavigate()
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (location.state && location.state?.text && location.state?.link && location.state?.linkName) {
      setSuccess(true)
      if (window !== undefined) {
        const isGTagged = Boolean(localStorage.getItem('pre-sign-up-gtag'))
        if (window?.gtag && !isGTagged) {
          window.gtag('event', 'conversion', {
            'send_to': 'AW-16477198397/nu6BCKGAmZcZEL2w-LA9',
            'value': 1.0,
            'currency': 'BRL'
          })
          localStorage.setItem('pre-sign-up-gtag', true)
        } 
      } 
      return
    }
    navigate('/')
  }, []);

  return (
    <div className='success-page'>
      <header className="App-header">
        <a href="/"><Logo theme={theme} /></a>
        <div className='buttons-container'>
          <ThemeToggleButton />
          <LanguageSelect />
        </div>
      </header>
      <main>
        <div>
          {success ? (
            <>
              <h1>{t(location.state.text)}</h1>
              <a href={location.state.link}>{t(location.state.linkName)}</a>
            </>
          ) : (
            <h1>{t('loading')}</h1>
          )}
        </div>
      </main>
    </div>
  );
}