/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniCurrencyBangladeshi = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-currency-bangladeshi ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM5.93934 5.49996C6.88428 4.55502 8.5 5.22426 8.5 6.56062V8H14.25C14.6642 8 15 8.33579 15 8.75C15 9.16421 14.6642 9.5 14.25 9.5H8.5V13.775C8.5 14.0711 8.64363 14.23 8.75926 14.2739C9.1439 14.4198 9.56172 14.5 10 14.5C11.3936 14.5 12.5986 13.6853 13.1616 12.5037H12.75C12.3358 12.5037 12 12.1679 12 11.7537C12 11.3395 12.3358 11.0037 12.75 11.0037H13.2872C13.7494 11.0037 14.1744 11.2149 14.4434 11.5599C14.7206 11.9155 14.8262 12.4125 14.6272 12.8974C13.8806 14.7166 12.091 16 10 16C9.37719 16 8.77933 15.8857 8.22739 15.6764C7.37603 15.3536 7 14.513 7 13.775V9.5H5.75C5.33579 9.5 5 9.16421 5 8.75C5 8.33579 5.33579 8 5.75 8H7V6.56062L6.78033 6.78029C6.48744 7.07319 6.01256 7.07319 5.71967 6.78029C5.42678 6.4874 5.42678 6.01253 5.71967 5.71963L5.93934 5.49996Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
