import React, { useState, useRef, useEffect } from 'react';
import './App.css'; // Importe o arquivo CSS
import { collection, query, where, getDocs, addDoc, updateDoc, doc, serverTimestamp } from "firebase/firestore";
import { firestore } from './firebase-config'; // Your Firebase config import
import { useNavigate } from 'react-router-dom';
import { Button } from "./components/Button";
import { InputBasic } from "./components/InputBasic";
import { SelectBasic } from "./components/SelectBasic";
import { SelectMult } from "./components/SelectMult";
import { InputTextArea } from "./components/InputTextArea";
import { Popup } from './components/Popup';
import { Logo } from './components/Logo';
import { CircleOutline, DownArrowOutline, HeroiconsOutlineMoon, HeroiconsOutlineSun, IndivIConsSolidDiscord } from './icons';
import { LanguageSelect } from './components/LanguageSelect/LanguageSelect';
import { Trans, useTranslation } from 'react-i18next';
import i18n from './i18n';
import { useTheme } from './hooks/useTheme';

// Exemplo de como usar
// typeTitle("Jogo");
function PerguntasJogadorProfissional({ formData, typedTitles, handleChange, typeTitle, jogos }) {
  return (
    <div className='perguntas-jogador-profissional'>
      <div className='first-questions'>
        {/* Campo para Nickname */}
        <div className="field-margin">
          <InputBasic
            type="text"
            icon_class="HeroiconsOutlineUser"
            icon_extra_class="icon_solid_white"
            placeholderText="Seu nickname"
            prefixText={typedTitles.nick}
            name="nick"
            size="small"
            state="default"
            suffixText=""
            value={formData.nick}
            iconRight={false}
            onChange={handleChange}
          />
        </div>

        {/* Campo para Jogo */}
        <div className="field-margin">
          <SelectBasic
            type="jogo"
            prefixText={typedTitles.jogo}
            name="jogo"
            icon_class="HeroiconsOutlineRocketLaunch"
            icon_extra_class="icon_solid_white"
            size="large"
            state="default"
            suffixText=""
            value={formData.jogo}
            
            onChange={handleChange}
            lista={jogos}
          />
        </div>

        {/* Campo para Elo */}
        <div className="field-margin">
          <InputBasic
            type="elo"
            placeholderText="Seu elo/ranking"
            icon_class="HeroiconsOutlineTrophy"
            icon_extra_class="icon_solid_white"
            prefixText={typedTitles.elo}
            name="elo"
            size="large"
            state="default"
            suffixText=""
            value={formData.elo}
            
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="heading3_br">
          <div className= 'text-wrapper3'>
            Participou de algum torneio em 2023? Se sim, qual?
          </div>
        </div>
        <div className="field-margin">
          <InputBasic
            type="text"
            placeholderText=""
            icon_class="HeroiconsOutlineTrophy"
            icon_extra_class="icon_solid_white"
            name="jogador_torneio"
            size="large"
            state="default"
            suffixText=""
            value={formData.jogador_torneio}
            onChange={handleChange}
          />
      </div>
      <div className="heading3_br">
          <div className= 'text-wrapper3'>
            Com que time participou? Ou se não em que time está agora? (Responda apenas "Não" se não aplicar)
          </div>
        </div>
        <div className="field-margin">
          <InputBasic
            type="text"
            placeholderText=""
            name="jogador_time"
            size="large"
            state="default"
            suffixText=""
            value={formData.jogador_time}
            onChange={handleChange}
          />
      </div>
    </div>
  );
}


function PerguntasStreamerInfluenciador(
  {
    formData, 
    handleTemasChange, 
    handleJogosChange, 
    temas_streaming, 
    jogos, 
    typedTitles, 
    typeTitle,
    streaming_channels,
    handleChannelsChange,
    handleTextareaChange,
    handleChange
  }
) {
  return (
    <div className='perguntas-streamer-influenciador'>
      <div className="heading3_br">
          <div className= 'text-wrapper3'>
            Há quanto tempo está streamando? (meses)
          </div>
        </div>
        <div className="field-margin">
          <InputBasic
            type="text"
            placeholderText=""
            name="stream_months"
            size="large"
            state="default"
            suffixText=""
            value={formData.stream_months}
            onChange={handleChange}
          />
      </div>
      <div className="heading3_br">
          <div className= 'text-wrapper3'>
            Qual / Quais suas plataformas atuais de Streaming?
          </div>
        </div>
        <div className="field-margin">
          <SelectMult
            selecionados={formData.channels_streaming}
            placeholder="Selecione Plataformas"
            setSelecionados={handleChannelsChange}
            listaOpcoes={streaming_channels}
          />
      </div>
      <div className="heading3_br">
        <div className= 'text-wrapper3'>
          Quais tipos de Stream costuma fazer?
        </div>
      </div>
      <div className="field-margin">
        <SelectMult
          selecionados={formData.temas_streaming}
          placeholder="Selecione Temas"
          setSelecionados={handleTemasChange}
          listaOpcoes={temas_streaming}
        />
      </div>
      <div className="heading3_br">
        <div className= 'text-wrapper3'>
          Algum destes jogos está entre seu top 3 em tempo de stream? 
        </div>
      </div>
      <div className="field-margin">
        <SelectMult
          selecionados={formData.jogos_streaming}
          placeholder="Selecione Jogos"
          setSelecionados={handleJogosChange}
          listaOpcoes={jogos}
        />
      </div>
      <div className="heading3_br">
          <div className= 'text-wrapper3'>
            Tem um Linktree? Se não, é só colar aqui os links que gostaria que seus viewers tivessem acesso, separados por ","
          </div>
        </div>
        <div className="field-margin">
          <InputTextArea
            name="linkTree"
            value={formData.linkTree}
            onChange={handleChange}
            placeholderText="site1.com/perfil, site2.com/perfil, site3.com/perfil"
          />
      </div>
    </div>
  );
}

function PerguntasTecnicoCoach  ({
    formData,  
    handleJogosChange, 
    temas_streaming, 
    jogos, 
    typedTitles, 
    typeTitle,
    handleTextareaChange,
    handleChange
  }
) {
  return (
    <div className='perguntas-tecnico-coach'>
      <div className="heading3_br">
          <div className= 'text-wrapper3'>
            Há quanto tempo trabalha com e-Sports? (meses)
          </div>
        </div>
        <div className="field-margin">
          <InputBasic
            type="text"
            placeholderText=""
            name="stream_months"
            size="large"
            state="default"
            suffixText=""
            value={formData.stream_months}
            onChange={handleChange}
          />
      </div>
      <div className="heading3_br">
        <div className= 'text-wrapper3'>
          Com quais jogos trabalhou mais durante a sua carreira?
        </div>
      </div>
      <div className="field-margin">
        <SelectMult
          selecionados={formData.jogos_streaming}
          placeholder="Selecione Jogos"
          setSelecionados={handleJogosChange}
          listaOpcoes={jogos}
        />
      </div>
      <div className="heading3_br">
          <div className= 'text-wrapper3'>
            Se algum, que time teve destaque ou foi o mais recente com o qual teve vínculo em 2023?
          </div>
        </div>
        <div className="field-margin">
          <InputBasic
            type="text"
            placeholderText=""
            name="jogador_time"
            size="large"
            state="default"
            suffixText=""
            value={formData.jogador_time}
            onChange={handleChange}
          />
      </div>
      <div className="heading3_br">
          <div className= 'text-wrapper3'>
            Se algum, de que torneio seu time participou nesse período?
          </div>
        </div>
        <div className="field-margin">
          <InputBasic
            type="text"
            placeholderText=""
            name="jogador_torneio"
            size="large"
            state="default"
            suffixText=""
            value={formData.jogador_torneio}
            onChange={handleChange}
          />
      </div>
      <div className="heading3_br">
          <div className= 'text-wrapper3'>
            Tem um Linktree? Portfolio? Compartilhe conosco!
          </div>
        </div>
        <div className="field-margin">
          <InputTextArea
            name="linkTree"
            value={formData.linkTree}
            onChange={handleChange}
            placeholderText="site1.com/perfil, site2.com/perfil, site3.com/perfil"
          />
      </div>
    </div>
  );
}

function PerguntasAnalistaJogo({
    formData,  
    handleJogosChange, 
    temas_streaming, 
    jogos, 
    typedTitles, 
    typeTitle,
    handleTextareaChange,
    handleChange
  }
) {
  return (
    <div className='perguntas-analista-jogo'>
      <div className="heading3_br">
          <div className= 'text-wrapper3'>
            Há quanto tempo trabalha com e-Sports? (meses)
          </div>
        </div>
        <div className="field-margin">
          <InputBasic
            type="text"
            placeholderText=""
            name="stream_months"
            size="large"
            state="default"
            suffixText=""
            value={formData.stream_months}
            onChange={handleChange}
          />
      </div>
      <div className="heading3_br">
        <div className= 'text-wrapper3'>
          Com quais jogos trabalhou mais durante a sua carreira?
        </div>
      </div>
      <div className="field-margin">
        <SelectMult
          selecionados={formData.jogos_streaming}
          placeholder="Selecione Jogos"
          setSelecionados={handleJogosChange}
          listaOpcoes={jogos}
        />
      </div>
      <div className="heading3_br">
          <div className= 'text-wrapper3'>
            Se algum, que time teve destaque ou foi o mais recente com o qual teve vínculo em 2023?
          </div>
        </div>
        <div className="field-margin">
          <InputBasic
            type="text"
            placeholderText=""
            name="jogador_time"
            size="large"
            state="default"
            suffixText=""
            value={formData.jogador_time}
            onChange={handleChange}
          />
      </div>
      <div className="heading3_br">
          <div className= 'text-wrapper3'>
            Se algum, de que torneio seu time participou nesse período?
          </div>
        </div>
        <div className="field-margin">
          <InputBasic
            type="text"
            placeholderText=""
            name="jogador_torneio"
            size="large"
            state="default"
            suffixText=""
            value={formData.jogador_torneio}
            onChange={handleChange}
          />
      </div>
      <div className="heading3_br">
          <div className= 'text-wrapper3'>
            Tem um Linktree? Portfolio? Compartilhe conosco!
          </div>
        </div>
        <div className="field-margin">
          <InputTextArea
            name="linkTree"
            value={formData.linkTree}
            onChange={handleChange}
            placeholderText="site1.com/perfil, site2.com/perfil, site3.com/perfil"
          />
      </div>
    </div>
  );
}

function PerguntasComentaristaHost({
    formData,  
    handleJogosChange, 
    temas_streaming, 
    jogos, 
    typedTitles, 
    typeTitle,
    handleTextareaChange,
    handleChange
  }
) {
  return (
    <div className='perguntas-comentarista-host'>
      <div className="heading3_br">
          <div className= 'text-wrapper3'>
            Há quanto tempo trabalha com e-Sports? (meses)
          </div>
        </div>
        <div className="field-margin">
          <InputBasic
            type="text"
            placeholderText=""
            name="stream_months"
            size="large"
            state="default"
            suffixText=""
            value={formData.stream_months}
            onChange={handleChange}
          />
      </div>
      <div className="heading3_br">
        <div className= 'text-wrapper3'>
          Com quais jogos trabalhou mais durante a sua carreira?
        </div>
      </div>
      <div className="field-margin">
        <SelectMult
          selecionados={formData.jogos_streaming}
          placeholder="Selecione Jogos"
          setSelecionados={handleJogosChange}
          listaOpcoes={jogos}
        />
      </div>
      <div className="heading3_br">
          <div className= 'text-wrapper3'>
            Tem um Linktree? Portfolio? Compartilhe conosco!
          </div>
        </div>
        <div className="field-margin">
          <InputTextArea
            name="linkTree"
            value={formData.linkTree}
            onChange={handleChange}
            placeholderText="site1.com/perfil, site2.com/perfil, site3.com/perfil"
          />
      </div>
    </div>
  );
}




function PerguntasTime({
    formData,  
    handleJogosChange, 
    temas_streaming, 
    jogos, 
    typedTitles, 
    typeTitle,
    handleTextareaChange,
    handleChange,
    worker_position
  }
) {
  return (
    <>
      <div className="heading3_br">
          <div className= 'text-wrapper3'>
            Há quanto tempo trabalha com e-Sports? (meses)
          </div>
        </div>
        <div className="field-margin">
          <InputBasic
            type="text"
            placeholderText=""
            name="stream_months"
            size="large"
            state="default"
            suffixText=""
            value={formData.stream_months}
            onChange={handleChange}
          />
      </div>
      <div className="heading3_br">
          <div className= 'text-wrapper3'>
            Em que time trabalha/trabalhou mais recentemente?
          </div>
        </div>
        <div className="field-margin">
          <InputBasic
            type="text"
            placeholderText=""
            name="jogador_time"
            size="large"
            state="default"
            suffixText=""
            value={formData.jogador_time}
            onChange={handleChange}
          />
      </div>
      <div className="heading3_br">
        <div className= 'text-wrapper3'>
          Com quais jogos/lineups seu time trabalha atualmente?
        </div>
      </div>
      <div className="field-margin">
        <SelectMult
          selecionados={formData.jogos_streaming}
          placeholder="Selecione Jogos"
          setSelecionados={handleJogosChange}
          listaOpcoes={jogos}
        />
      </div>
      <div className="heading3_br">
          <div className= 'text-wrapper3'>
            Qual seu título hoje na instituição?
          </div>
        </div>
        <div className="field-margin">
          <InputBasic
            type="text"
            placeholderText=""
            name="worker_position"
            size="large"
            state="default"
            suffixText=""
            value={formData.worker_position}
            onChange={handleChange}
          />
      </div>
      <div className="heading3_br">
          <div className= 'text-wrapper3'>
            Tem um Linktree? Portfolio? Compartilhe conosco!
          </div>
        </div>
        <div className="field-margin">
          <InputTextArea
            name="linkTree"
            value={formData.linkTree}
            onChange={handleChange}
            placeholderText="site1.com/perfil, site2.com/perfil, site3.com/perfil"
          />
      </div>
    </>
  );
}


function PerguntasEvento({
  formData,
  handleChange,
}) {
  return (
    <>
    <div className="heading3_br">
          <div className= 'text-wrapper3'>
            Compartilhe conosco os links dos eventos com os que trabalha! Assim como qualquer link pessoal (separados por ",") 
          </div>
        </div>
        <div className="field-margin">
          <InputTextArea
            name="linkTree"
            value={formData.linkTree}
            onChange={handleChange}
            placeholderText="site1.com/perfil, site2.com/perfil, site3.com/perfil"
          />
      </div>
    </>
  );
}

function PerguntasOutros({
  formData,
  handleChange,
}) {
  return (
    <>
    <div className="heading3_br">
          <div className= 'text-wrapper3'>
            Descreva um pouco o que faz dentro do cenário! Se puder, compartilhe conosco seus links separados por ","
          </div>
        </div>
        <div className="field-margin">
          <InputTextArea
            name="linkTree"
            value={formData.linkTree}
            onChange={handleChange}
            placeholderText="Fala mais!"
          />
      </div>
    </>
  );
}



const LandingPage = () => {
  const typingTimeoutId = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation('index');
  const { theme, ThemeToggleButton } = useTheme()
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState({
    text: '',
    link: '',
    link_name: '',
    buttonText: ''
  });
  
  /*const [formData, setFormData] = useState({
    nick: '',
    email: '',
    proficao: '',
    ano_nacimento: '',
    facebook: '',
    twitter: '',
    instagram: '',
    discord: '',
    linkedin: '',
    twitch: '',
    jogo: '',
    elo: '',
    temas_streaming: [],
    jogos_streaming: [],
    channels_streaming: [],
    linkTree: '',
    stream_months:'',
    jogador_time:'',
    jogador_torneio:'',
    worker_position:'',
  });*/

  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    ano_nacimento: '',
    aceita_termos: false,
    aceita_comunicacao: false
  });
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleTemasChange = (novosTemas) => {
    setFormData({ ...formData, temas_streaming: novosTemas });
  };

  const handleJogosChange = (novosJogos) => {
    setFormData({ ...formData, jogos_streaming: novosJogos });
  };

  const handleChannelsChange = (novosChannels) => {
    setFormData({ ...formData, channels_streaming: novosChannels });
  };

  const [typedTitles, setTypedTitles] = useState({
    name: 'Nome',
    nick: 'Nickname',
    email: 'E-mail',
    proficao: 'Profissão',
    ano_nacimento: 'Ano de Nascimento',
    facebook: 'Facebook',
    twitter: 'Twitter',
    instagram: 'Instagram',
    discord: 'Discord',
    linkedin: 'Linkedin',
    twitch: 'Twitch',
    jogo: 'Jogo',
    elo: 'Elo',
    country: 'País'
  });

  const campos = [
    'name',
    'nick',
    'email',
    'proficao',
    'ano_nacimento',
    'facebook',
    'twitter',
    'instagram',
    'discord',
    'linkedin',
    'jogo',
    'elo',
  ]

  const americanCountries = [
    'Antigua and Barbuda',
    'Argentina',
    'Bahamas',
    'Barbados',
    'Belize',
    'Bolivia',
    'Brazil',
    'Canada',
    'Chile',
    'Colombia',
    'Costa Rica',
    'Cuba',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'El Salvador',
    'Grenada',
    'Guatemala',
    'Guyana',
    'Haiti',
    'Honduras',
    'Jamaica',
    'Mexico',
    'Nicaragua',
    'Panama',
    'Paraguay',
    'Peru',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Vincent and the Grenadines',
    'Suriname',
    'Trinidad and Tobago',
    'United States',
    'Uruguay',
    'Venezuela'
  ];


  const jogos = [
    "League of Legends",
    "Counter Strike: Global Offensive",
    "Valorant",
    "Dota 2",
    "Fortnite",
    "Overwatch",
    "Rainbow Six Siege",
    "Rocket League",
    "StarCraft II",
    "Call of Duty",
    "Apex Legends",
    "FIFA",
    "PUBG",
    "Hearthstone",
    "Super Smash Bros.",
    "Street Fighter",
    "Tekken",
    "Mortal Kombat",
    "Free Fire",
    "Mobile Legends",
    "Arena of Valor",
    "Clash Royale",
    "Outro",
    "Nenhum"
  ];

  const streaming_channels = [
    'Twitch',
    'YouTube Gaming',
    'Facebook Gaming',
    'Trovo',
    'TikTok Live',
    'Instagram Live',
    'Caffeine',
    'Bigo Live',
    'AfreecaTV',
    'DLive',
    'Douyu',
    'Huya',
    'Niconico',
    'Bilibili',
    'GosuGamers',
    'Mobcrush',
    'Owncast'
    // Add any other active platforms here
  ];

  const temas_streaming = [
  // Categorias de Jogos
  "Battle Royale (ex: Fortnite, PUBG, Apex Legends)",
  "MOBA (ex: League of Legends, Dota 2)",
  "FPS (ex: Call of Duty, Valorant, Counter-Strike: Global Offensive)",
  "RPG (ex: The Witcher 3, Final Fantasy, Skyrim)",
  "MMORPG (ex: World of Warcraft, Final Fantasy XIV)",
  "Estratégia (ex: StarCraft II, Civilization VI)",
  "Simuladores (ex: The Sims, Cities: Skylines, Flight Simulator)",
  "Jogos Indie",
  "Jogos de Esportes (ex: FIFA, NBA 2K, Madden NFL)",
  "Jogos de Cartas/Tabuleiro (ex: Hearthstone, Magic: The Gathering Arena)",
  "Jogos de Aventura/Exploração (ex: Minecraft, Terraria, Subnautica)",
  "Retro Gaming",

  // Temas de Streaming Fora de Jogos
  "Just Chatting",
  "ASMR",
  "Cozinha e Gastronomia",
  "Música e Performance",
  "Artes e Artesanato",
  "Fitness e Saúde",
  "Tutoriais e Educação",
  "Reações e Análises",
  "Talk Shows e Podcasts",
  "Vida e Estilo",
  "Viagens e Natureza",
  "Tecnologia e Ciência"
];

  const proficoes = [
    "Jogador Profissional",
    "Streamer/Influenciador",
    "Técnico/Coach",
    "Analista de Jogo",
    "Comentarista/Host",
    "Trabalho em um time/equipe de e-Sports",
    "Trabalho com eventos que incluem e-Sports/streamers",
    "Outros"
  ];

  // Refs para cada campo de entrada
  const nameRef = useRef(null);
  const nickRef = useRef(null);
  const emailRef = useRef(null);
  const proficaoRef = useRef(null);
  const anoNascimentoRef = useRef(null);
  const facebookRef = useRef(null);
  const twitterRef = useRef(null);
  const instagramRef = useRef(null);
  const discordRef = useRef(null);
  const linkedinRef = useRef(null);
  const twitchRef = useRef(null);
  const jogoRef = useRef(null);
  const eloRef = useRef(null);
  const countryRef = useRef(null);

const typeTitle = (value, handle) => {
  let index = 0; // Índice para acompanhar a posição atual na string

  // Limpar qualquer digitação anterior
  if (typingTimeoutId.current) {
    clearInterval(typingTimeoutId.current);
  }

  // Função para adicionar um caractere por vez
  const typeChar = () => {
    setTypedTitles(prevTitles => {
      const typed = value.slice(0, index); // Parte da string já "digitada"
      index++;

      // Retorna um novo estado com o texto atualizado
      return Object.keys(prevTitles).reduce((newTitles, key) => {
        newTitles[key] = key === handle ? typed : '';
        return newTitles;
      }, {});
    });

    // Se a string inteira foi digitada, limpa o intervalo
    if (index > value.length) {
      clearInterval(typingTimeoutId.current);
    }
  };

  // Configurar intervalo para simular digitação
  typingTimeoutId.current = setInterval(typeChar, 100); // Ajuste a velocidade conforme necessário
};


const handleChange = (e) => {
  let value = e.target.value;
  if (e.target.name === 'aceita_termos' || e.target.name === 'aceita_comunicacao') {
    value = e.target.checked
  }
  // Atualiza o estado com o nome e o valor do campo que foi alterado
  setFormData({ ...formData, [e.target.name]: value });
  /*if (e.target.name === 'proficao' && e.target.value === '') {
    setTotalPages(0)
  } else if (e.target.name === 'proficao' && e.target.value) {
    setTotalPages(1)
  }*/
};

const handleDateChange = (value, context) => {
  if (context.validationError || !value?.$d) return
  const epochTime = new Date(value.$d).getTime()
  setFormData({ ...formData, ano_nacimento: epochTime })
}

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

const handleNextPage = (e) => {
  e.preventDefault();
  const nextPage = page + 1;
  setPage(nextPage);
}

const handlePrevPage = (e) => {
  e.preventDefault();
  const prevPage = page - 1;
  setPage(prevPage);
  setFormData({
    ...formData,
    nick: '',
    jogo: '',
    elo: '',
    jogador_torneio: '',
    jogador_time: '',
    stream_months: '',
    channels_streaming: [],
    temas_streaming: [],
    jogos_streaming: [],
    linkTree: '',
    worker_position: '',
  });
}

const handleFormSubmit = (e) => {
  e.preventDefault();
}

const handleSubmit = async (e) => {
  e.preventDefault();

  if (isSubmitting) return;
  setIsSubmitting(true);

  if (!formData.nome) {
    setPopupContent({
      text: t('popup.nameError'),
      buttonText: t('popup.closeButton'),
    });
    setShowPopup(true);
    return; // Stop the function here
  }

  // Check if the email is empty or doesn't meet your validation criteria
  if (!formData.email || !isValidEmail(formData.email)) {
    setPopupContent({
      text: t('popup.emailError'),
      buttonText: t('popup.closeButton'),
    });
    setShowPopup(true);
    return; // Stop the function here
  }

  if (!formData.ano_nacimento) {
    setPopupContent({
      text: t('popup.birthDateError'),
      buttonText: t('popup.closeButton'),
    });
    setShowPopup(true);
    return; // Stop the function here
  }

  if (!formData.aceita_termos) {
    setPopupContent({
      text: t('popup.termsError'),
      buttonText: t('popup.closeButton'),
    });
    setShowPopup(true);
    return; // Stop the function here
  }

  let popupMessage = '';

  // Define the collection reference
  const submissionsRef = collection(firestore, "submissions");

  // Create a query to check if email exists
  const q = query(submissionsRef, where("email", "==", formData.email));
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    // Email already exists, update existing document
    for (const document of querySnapshot.docs) {
      const docRef = doc(firestore, "submissions", document.id);
      await updateDoc(docRef, {
        ...formData,
        updated_at: serverTimestamp()
      });
      console.log("Data updated successfully");
      popupMessage = 'popup.updateSuccess';
    }
  } else {
    // Email does not exist, create a new document
    await addDoc(submissionsRef, {
      ...formData,
      created_at: serverTimestamp()
    });
    console.log("New data added successfully");
    popupMessage = 'popup.successMessage';
  }

  /* const popupMessage = 'popup.successMessage'; */
  
  setFormData({
    nome: '',
    email: '',
    ano_nacimento: '',
    aceita_termos: false,
    aceita_comunicacao: false
  })
  if (anoNascimentoRef?.current) {
    anoNascimentoRef.current.classList.add('date-placeholder-shown')
  }
  if (window?.twq) {
    window.twq('event', 'tw-on4vr-on4w5', {});
  }
  setIsSubmitting(false);
  navigate('/success', {
    state: {
      text: popupMessage,
      link: 'https://discord.gg/CdvZebCkdK',
      linkName: 'popup.successLink',
    }
  })
};

const handleClosePopup = () => {
  setPopupContent({
    text: '',
    link: '',
    link_name: '',
    buttonText: ''
  });
  setShowPopup(false);
};

const renderizarPerguntasProfissao = () => {
switch (formData.proficao) {
  case "Jogador Profissional":
    return (
      <PerguntasJogadorProfissional
        formData={formData}
        typedTitles={typedTitles}
        handleChange={handleChange}
        typeTitle={typeTitle}
        jogos={jogos}
      />
    );
  case "Streamer/Influenciador":
    return (
      <PerguntasStreamerInfluenciador 
        formData={formData}
        typedTitles={typedTitles}
        handleTemasChange={handleTemasChange}
        typeTitle={typeTitle}
        temas_streaming={temas_streaming}
        handleJogosChange={handleJogosChange}
        jogos={jogos}
        streaming_channels={streaming_channels}
        handleChannelsChange={handleChannelsChange}
        handleChange={handleChange}
      />
    );
  case "Técnico/Coach":
    return <PerguntasTecnicoCoach 
        formData={formData}
        typedTitles={typedTitles}
        typeTitle={typeTitle}
        handleJogosChange={handleJogosChange}
        jogos={jogos}
        handleChange={handleChange}
      />;
  case "Analista de Jogo":
    return <PerguntasAnalistaJogo 
        formData={formData}
        typedTitles={typedTitles}
        typeTitle={typeTitle}
        handleJogosChange={handleJogosChange}
        jogos={jogos}
        handleChange={handleChange}
      />;
  case "Comentarista/Host":
    return <PerguntasComentaristaHost 
        formData={formData}
        typedTitles={typedTitles}
        typeTitle={typeTitle}
        handleJogosChange={handleJogosChange}
        jogos={jogos}
        handleChange={handleChange}
      />;
  case "Trabalho em um time/equipe de e-Sports":
    return <PerguntasTime
        formData={formData}
        typedTitles={typedTitles}
        typeTitle={typeTitle}
        handleJogosChange={handleJogosChange}
        jogos={jogos}
        handleChange={handleChange}
      />;
  case "Trabalho com eventos que incluem e-Sports/streamers":
    return <PerguntasEvento 
        formData={formData}
        typedTitles={typedTitles}
        typeTitle={typeTitle}
        handleChange={handleChange}
      />;
  case "Outros":
    return <PerguntasOutros
        formData={formData}
        typedTitles={typedTitles}
        typeTitle={typeTitle}
        handleChange={handleChange}
      />;
  default:
    return false;
  }
};

  return (
  <div className='main-wrapper'>
    <div className="background-container-desktop">
      <div />
      <div className='background' />  
    </div>
    <main>
      <section className='title-section'>
        <header className="App-header">
          <Logo theme={theme} />
          <div className='buttons-container'>
            <ThemeToggleButton />
            <LanguageSelect />
          </div>
        </header>
        <div className='title-content'>
          <div>
            <h1 className='landing-title'>
              <Trans t={t} i18nKey={'main.title'}>
              <span className='no-break'></span>
              <br/>
              </Trans>
            </h1>
            <h1 className='landing-title landing-title-mobile'>
              <Trans t={t} i18nKey={'main.title'}>
              <span className='no-break'></span>
              </Trans>
            </h1>
            <p className='landing-subtitle'>
              <Trans t={t} i18nKey='main.subtitle'>
                O seu centro de conexões profissionais no mundo dos <span className='no-break'>e-Sports.</span>
              </Trans>
            </p>
            <p className='landing-text landing-email'>
              {t('main.contact.beforeBr')}<br/>{t('main.contact.afterBr')} <a className='landing-link' href="mailto:contact@spectate.pro">contact@spectate.pro</a>
            </p>
          </div>
          <footer className='landing-footer'>
            <p className='landing-text copyright'>Spectate.pro © 2024</p>
            <p className='landing-text'>
              {t('main.discord')}
              <a className='landing-discord landing-link' target='_blank' href="https://discord.gg/CdvZebCkdK" rel="noreferrer">
                <IndivIConsSolidDiscord className={'landing-icon'} height={'auto'} />
                <span>discord</span>
              </a>
            </p>
          </footer>
        </div>
        <div className='arrow-wrapper'>
          <a href='#form-section'>
            <CircleOutline className='circle-outline' />
            <DownArrowOutline className='arrow-1' />
            <DownArrowOutline className='arrow-2' />
          </a>
        </div>
      </section>
      <section className='form-section' id='form-section'>
        <div className="background-container-mobile">
          <div className='background' />
        </div>
        <h2>{t('form.callToAction')}</h2>
        <form className="form" onSubmit={handleFormSubmit}>
          {page === 0 && (
            <>
            <div className="heading2">
              <div className= 'text-wrapper2'>
                {t('form.title')}
              </div>
            </div>
            <div className="row2">
              <div className="col2 form-about">
                <div className="field-margin">
                  <InputBasic
                    type="nome"
                    icon_class="HeroiconsOutlineUser"
                    placeholderText={t('form.name.placeholder')}
                    prefixText={t('form.name.title')}
                    name="nome"
                    ref={nameRef}
                    size="small"
                    state="default"
                    suffixText=""
                    value={formData.nome}
                    iconRight={false}
                    onChange={handleChange}
                    //onKeyPress={handleKeyPress}
                  />
                </div>
                <div className="field-margin">
                  <InputBasic
                      type="email"
                      icon_class="HeroiconsOutlineEnvelope"
                      placeholderText={t('form.email.placeholder')}
                      prefixText={t('form.email.title')}
                      name="email"
                      ref={emailRef}
                      size="large"
                      state="default"
                      suffixText=""
                      value={formData.email}
                      
                      onChange={handleChange}
                      //onKeyPress={handleKeyPress}
                  />
                </div>

                {/*<div className="field-margin">
                  <SelectBasic
                      type="proficao"
                      prefixText={typedTitles.proficao}
                      icon_class="HeroiconsOutlineBriefcase"
                      name="proficao"
                      ref={proficaoRef}
                      size="large"
                      state="default"
                      suffixText=""
                      value={formData.proficao}
                      
                      onChange={handleChange}
                      //onKeyPress={handleKeyPress}
                      lista={proficoes}
                  />
                </div>*/}

                <div className="field-margin">
                  <InputBasic
                      type="date"
                      placeholderText={t('form.birthDate.placeholder')}
                      icon_class="HeroiconsOutlineCake"
                      prefixText={t('form.birthDate.title')}
                      name="ano_nacimento"
                      ref={anoNascimentoRef}
                      size="large"
                      state="default"
                      suffixText=""
                      value={formData.ano_nacimento}
                      className="birth-date-input"
                      onChange={handleDateChange}
                      //onKeyPress={handleKeyPress}
                  />
                </div>

                {/*<div className="field-margin">
                  <SelectBasic
                      type="country"
                      prefixText={typedTitles.country}
                      icon_class="HeroiconsOutlineFlag"
                      name="country"
                      ref={countryRef}
                      size="large"
                      state="default"
                      suffixText=""
                      value={formData.country}
                      
                      onChange={handleChange}
                      //onKeyPress={handleKeyPress}
                      lista={americanCountries}
                  />
                </div>*/}
                <div className="field-margin checkbox">
                  <input type='checkbox' name="aceita_termos" id="aceita_termos" onChange={handleChange} checked={formData.aceita_termos} />
                  <label htmlFor="aceita_termos">
                    <Trans t={t} i18nKey='form.acceptTerms'>
                      Aceito os <a className='form-link' href='/terms' target="_blank" rel="noreferrer">termos de uso</a>
                    </Trans>
                  </label>
                </div>

                <div className="field-margin checkbox">
                  <input type='checkbox' name="aceita_comunicacao" id="aceita_comunicacao" onChange={handleChange} checked={formData.aceita_comunicacao} />
                  <label htmlFor="aceita_comunicacao">{t('form.acceptCommunication')}</label>
                </div>
              </div>
            </div>
            {/*<div className="heading2">
              <div className= 'text-wrapper2'>
                Suas redes sociais
              </div>
            </div>
            <div className="row2">
              <div className="col2 form-socials">
                <div className="field-margin">
                  <InputBasic
                    type="text"
                    icon_class="IndivIConsSolidFacebook"
                    icon_extra_class="icon_solid_purple"
                    placeholderText="facebook.com/exemplo"
                    prefixText={typedTitles.facebook}
                    prefix={false}
                    name="facebook"
                    ref={facebookRef}
                    size="small"
                    state="default"
                    suffixText=""
                    value={formData.facebook}
                    iconRight={false}
                    onChange={handleChange}
                  />
                </div>
                <div className="field-margin">
                  <InputBasic
                      type="twitter"
                      icon_class="IndivIConsSolidTwitter"
                      icon_extra_class="icon_solid_purple"
                      placeholderText="twitter.com/exemplo"
                      prefixText={typedTitles.twitter}
                      prefix={false}
                      name="twitter"
                      ref={twitterRef}
                      size="large"
                      state="default"
                      suffixText=""
                      value={formData.twitter}
                      
                      onChange={handleChange}
                  />
                </div>
                <div className="field-margin">
                  <InputBasic
                      type="instagram"
                      icon_class="IndivIConsSolidInstagram"
                      icon_extra_class="icon_solid_purple"
                      placeholderText="instagram.com/exemplo"
                      prefixText={typedTitles.instagram}
                      prefix={false}
                      name="instagram"
                      ref={instagramRef}
                      size="large"
                      state="default"
                      suffixText=""
                      value={formData.instagram}
                      
                      onChange={handleChange}
                  />
                </div>
                <div className="field-margin">
                  <InputBasic
                      type="discord"
                      icon_class="IndivIConsSolidDiscord"
                      icon_extra_class="icon_solid_purple"
                      placeholderText="exemplo"
                      prefixText={typedTitles.discord}
                      prefix={false}
                      name="discord"
                      ref={discordRef}
                      size="large"
                      state="default"
                      suffixText=""
                      value={formData.discord}
                      
                      onChange={handleChange}
                  />
                </div>
                <div className="field-margin">
                  <InputBasic
                      type="linkedin"
                      icon_class="IndivIConsSolidLinkedin"
                      icon_extra_class="icon_solid_purple"
                      placeholderText="linkedin.com/in/exemplo"
                      prefixText={typedTitles.linkedin}
                      prefix={false}
                      name="linkedin"
                      ref={linkedinRef}
                      size="large"
                      state="default"
                      suffixText=""
                      value={formData.linkedin}
                      
                      onChange={handleChange}
                  />
                </div>
                <div className="field-margin">
                  <InputBasic
                      type="twitch"
                      icon_class="IndivIConsSolidTwitch"
                      icon_extra_class="icon_solid_purple"
                      placeholderText="twitch.tv/exemplo"
                      prefixText={typedTitles.twitch}
                      prefix={false}
                      name="twitch"
                      ref={twitchRef}
                      size="large"
                      state="default"
                      suffixText=""
                      value={formData.twitch}
                      
                      onChange={handleChange}
                  />
                </div>
              </div>
            </div>*/}
            </>
          )}

          {/*{page === 1 && (
            <>
            <div className="heading2">
              <div className= 'text-wrapper2'>
                  {formData.proficao}
              </div>
            </div>
            <div className="row2">
              <div className="col2">
                {renderizarPerguntasProfissao()}
              </div>
            </div>  
            </>
          )}*/}
          
        <div className="field-margin center-button">
          <Button size="large" state="default" text={t('form.submitButton')} type="primary" onClick={handleSubmit} />
          {/*{page < totalPages ? (
            <Button size="large" state="default" text="Próximo" type="primary" onClick={handleNextPage} />
          ) : (
            <>
            {page > 0 ? (
              <div className='buttons-wrapper'>
                <Button size="large" state="default" text="Voltar" type="primary" onClick={handlePrevPage} />
                <Button size="large" state="default" text="Pré-cadastrar" type="primary" onClick={handleSubmit} />
              </div>
            ) : (
              <Button size="large" state="default" text={t('form.submitButton')} type="primary" onClick={handleSubmit} />
            )}
            </>
            
          )}*/}
        </div>
        {showPopup && (
          <Popup
            onClose={handleClosePopup}
            {...popupContent}
          />
        )}
        </form>

        <footer className='form-footer'>
          <p className='landing-text landing-email'>
            {t('main.contact.beforeBr')}<br/>{t('main.contact.afterBr')} <a className='landing-link' href="mailto:contact@spectate.pro">contact@spectate.pro</a>
          </p>
          <p className='landing-text'>
            {t('main.discord')}
            <a className='landing-discord landing-link' target='_blank' href="https://discord.gg/CdvZebCkdK" rel="noreferrer">
              <IndivIConsSolidDiscord className={'landing-icon'} height={'auto'} />
              <span>discord</span>
            </a>
          </p>
        </footer>
      </section>
    </main>
  </div>
);

};

export default LandingPage;
