/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniGlobeAsiaAustralia = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-globe-asia-australia ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM11.5 16.3261C11.0185 16.4398 10.5163 16.5 10 16.5C7.98332 16.5 6.18115 15.5816 4.98891 14.1402L5.47949 13.1591C5.59335 12.9313 5.88103 12.8549 6.09287 12.9962L6.38719 13.1924C6.78056 13.4546 7.30434 13.4028 7.63864 13.0685C7.90427 12.8028 7.99702 12.4099 7.87823 12.0535L7.68051 11.4604C7.6409 11.3416 7.6947 11.2117 7.80674 11.1556L9.78018 10.1689C10.2846 9.9167 10.4539 9.27942 10.141 8.81015C9.99285 8.58788 10.1177 8.28635 10.3797 8.23396L10.5047 8.20896C11.3568 8.03855 12.0522 7.42466 12.3269 6.60033L12.3766 6.45127C12.4964 6.09194 12.4029 5.69577 12.135 5.42794L10.6464 3.93934C10.5527 3.84557 10.5 3.71839 10.5 3.58579V3.51894C13.8562 3.77426 16.5 6.57839 16.5 10C16.5 10.9822 16.2821 11.9136 15.8921 12.7484C15.6958 12.6128 15.4634 12.5258 15.212 12.5049L14.6464 11.9393C14.3651 11.658 13.9836 11.5 13.5858 11.5H13.4142C13.0164 11.5 12.6349 11.658 12.3536 11.9393L11.7612 12.5317C11.7232 12.5697 11.6793 12.6013 11.6312 12.6254L10.0528 13.4146C9.714 13.584 9.5 13.9302 9.5 14.309V14.5C9.5 15.0523 9.94772 15.5 10.5 15.5H11C11.2761 15.5 11.5 15.7239 11.5 16V16.3261Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
