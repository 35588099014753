/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsOutlineBackward = ({ className }) => {
  return (
    <svg
      className={`heroicons-outline-backward ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M20.9999 16.8114C20.9999 17.6752 20.0668 18.2167 19.3168 17.7882L12.2093 13.7267C11.4535 13.2949 11.4535 12.2051 12.2093 11.7732L19.3168 7.71178C20.0668 7.28321 20.9999 7.82475 20.9999 8.68855V16.8114Z"
        stroke="#7041F1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M11.2499 16.8114C11.2499 17.6752 10.3168 18.2167 9.56679 17.7882L2.4593 13.7267C1.70351 13.2949 1.70351 12.2051 2.4593 11.7732L9.56679 7.71178C10.3168 7.28321 11.2499 7.82475 11.2499 8.68855L11.2499 16.8114Z"
        stroke="#7041F1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
