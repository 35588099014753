/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsSolidVariable = ({ className }) => {
  return (
    <svg
      className={`heroicons-solid-variable ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M19.2535 2.2917C19.6447 2.15554 20.0722 2.3623 20.2083 2.75349C21.2033 5.61223 21.75 8.73358 21.75 12C21.75 15.2665 21.2033 18.3878 20.2083 21.2465C20.0722 21.6377 19.6447 21.8445 19.2535 21.7083C18.8623 21.5722 18.6555 21.1447 18.7917 20.7535C19.7313 18.0536 20.25 15.099 20.25 12C20.25 8.90106 19.7313 5.9464 18.7917 3.24655C18.6555 2.85535 18.8623 2.42785 19.2535 2.2917ZM5.02596 2.30482C5.40991 2.46024 5.59517 2.89748 5.43975 3.28143C4.3503 5.97284 3.75 8.9154 3.75 12C3.75 15.0846 4.3503 18.0272 5.43975 20.7186C5.59517 21.1026 5.40991 21.5398 5.02596 21.6952C4.64201 21.8506 4.20477 21.6654 4.04935 21.2814C2.88874 18.4142 2.25 15.2808 2.25 12C2.25 8.71924 2.88874 5.58579 4.04935 2.71861C4.20477 2.33466 4.64201 2.1494 5.02596 2.30482ZM9.30143 7.35697C10.1246 6.85041 11.205 7.24317 11.5106 8.16011L12.2266 10.3081L13.601 8.24642C14.2504 7.2723 15.5007 6.89748 16.5789 7.35365L16.7922 7.44391C17.1737 7.6053 17.3521 8.04539 17.1907 8.42687C17.0293 8.80834 16.5892 8.98676 16.2078 8.82536L15.9944 8.7351C15.5797 8.55965 15.0989 8.70381 14.8491 9.07847L12.8275 12.1108L13.9124 15.3656L15.3569 14.4767C15.7097 14.2596 16.1717 14.3696 16.3887 14.7223C16.6058 15.0751 16.4958 15.5371 16.1431 15.7541L14.6986 16.6431C13.8754 17.1496 12.795 16.7569 12.4894 15.8399L11.7734 13.692L10.399 15.7536C9.74958 16.7277 8.49934 17.1026 7.42112 16.6464L7.20777 16.5561C6.82629 16.3947 6.64788 15.9547 6.80927 15.5732C6.97067 15.1917 7.41075 15.0133 7.79223 15.1747L8.00558 15.2649C8.42028 15.4404 8.90114 15.2962 9.15091 14.9216L11.1725 11.8892L10.0876 8.63446L8.64307 9.52338C8.2903 9.74047 7.82834 9.63048 7.61126 9.27771C7.39417 8.92494 7.50416 8.46298 7.85693 8.24589L9.30143 7.35697Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
