import { useState, useEffect } from 'react';
import { HeroiconsOutlineMoon, HeroiconsOutlineSun } from '../icons';

const theme_key = 'spectate-landing-theme'

export const useTheme = () => {
  const [theme, setTheme] = useState(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(theme_key) || null;
    }
    return null;
  });

  useEffect(() => {
    if (theme) return;
    const htmlTag = document.documentElement;
    const currentTheme = htmlTag.getAttribute('data-theme');
    setTheme(currentTheme);
    localStorage.setItem(theme_key, currentTheme);
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === theme_key) {
        setTheme(event.newValue);
        const htmlTag = document.documentElement;
        htmlTag.setAttribute('data-theme', event.newValue || 'light');
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const toggleTheme = () => {
    const htmlTag = document.documentElement;
    const newTheme = theme === 'light' ? 'dark' : 'light';
    htmlTag.setAttribute('data-theme', newTheme);
    setTheme(newTheme);
    localStorage.setItem(theme_key, newTheme);
  };

  const ThemeToggleButton = () => (
    <button
      type='button'
      onClick={toggleTheme}
      className='theme-button'
    >
      {theme === 'dark' 
        ? <HeroiconsOutlineSun /> 
        : <HeroiconsOutlineMoon />
      }
    </button>
  );

  return { theme, ThemeToggleButton };
};