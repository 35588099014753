/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsSolidBackward = ({ className }) => {
  return (
    <svg
      className={`heroicons-solid-backward ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M9.19468 18.4394C10.4447 19.1536 11.9999 18.2511 11.9999 16.8114V14.4709L18.9447 18.4394C20.1947 19.1536 21.7499 18.2511 21.7499 16.8114L21.7499 8.68856C21.7499 7.24889 20.1947 6.34633 18.9447 7.06061L11.9999 11.029V8.68856C11.9999 7.24889 10.4447 6.34633 9.19469 7.06061L2.0872 11.122C0.827544 11.8418 0.827542 13.6581 2.0872 14.3779L9.19468 18.4394Z"
        fill="#7041F1"
      />
    </svg>
  );
};
