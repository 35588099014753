import { Logo } from '../../components/Logo';
import { LanguageSelect } from '../../components/LanguageSelect/LanguageSelect';
import { Trans, useTranslation } from 'react-i18next';
import './style.css';
import { useTheme } from '../../hooks/useTheme';

export const TermsPage = () => {
  const { t } = useTranslation('terms');
  const { theme, ThemeToggleButton } = useTheme();

  return (
    <div className='terms-page'>
      <header className="App-header">
        <a href="/"><Logo theme={theme} /></a>
        <div className='buttons-container'>
          <ThemeToggleButton />
          <LanguageSelect />
        </div>
      </header>
      <main>
        <div>
          <h2>{t('use.title')}</h2>
          <ol>
            {t('use.list', { returnObjects: true }).map((item, index) => (
              <div>
                <li key={index}>{item.title}</li>
                <p>
                  <Trans t={t}>
                    {item.description}
                    <a href={item.link}></a>
                  </Trans>
                </p>
                {item.list && (
                  <ul>
                    {item.list.map((subItem, subIndex) => (
                      <li key={subIndex}>
                        <span>{subItem}</span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </ol>
        </div>
        
        <div>
          <h2>{t('privacy.title')}</h2>
          <ol>
            {t('privacy.list', { returnObjects: true }).map((item, index) => (
              <div>
                <li key={index}>{item.title}</li>
                <p>
                  <Trans t={t}>
                    {item.description}
                    <a href={item.link}></a>
                  </Trans>
                </p>
                {item.list && (
                  <ul>
                    {item.list.map((subItem, subIndex) => (
                      <li key={subIndex}>
                        <span>{subItem}</span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </ol>
        </div>
        <p>
          <Trans t={t}>
            {t('agree')}
            <a href="https://spectate.pro"></a>
          </Trans>
        </p>
      </main>
    </div>
  );
}