/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsOutlineEnvelopeOpen = ({ className }) => {
  return (
    <svg
      className={`heroicons-outline-envelope-open ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M21.75 9V9.90609C21.75 10.7338 21.2955 11.4947 20.5667 11.8871L14.0893 15.375M2.25 9V9.90609C2.25 10.7338 2.70448 11.4947 3.43328 11.8871L9.91074 15.375M18.75 17.8846L14.0893 15.375M14.0893 15.375L13.0667 14.8244C12.4008 14.4658 11.5992 14.4658 10.9333 14.8244L9.91074 15.375M9.91074 15.375L5.25 17.8846M21.75 19.5C21.75 20.7426 20.7426 21.75 19.5 21.75H4.5C3.25736 21.75 2.25 20.7426 2.25 19.5L2.25 8.84391C2.25 8.01618 2.70448 7.25528 3.43328 6.86285L10.9333 2.82439C11.5992 2.4658 12.4008 2.4658 13.0667 2.82439L20.5667 6.86285C21.2955 7.25528 21.75 8.01618 21.75 8.84391V19.5Z"
        stroke="#7041F1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
