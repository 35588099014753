/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniVariable = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-variable ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M15.212 2.07899C15.5825 1.89394 16.033 2.04434 16.218 2.41492C17.3587 4.69914 18 7.27574 18 9.99998C18 12.7242 17.3587 15.3008 16.218 17.585C16.033 17.9556 15.5825 18.106 15.212 17.921C14.8414 17.7359 14.691 17.2855 14.876 16.9149C15.9151 14.8341 16.5 12.4864 16.5 9.99998C16.5 7.51353 15.9151 5.16585 14.876 3.08504C14.691 2.71446 14.8414 2.26404 15.212 2.07899ZM4.78803 2.07899C5.15861 2.26404 5.30901 2.71446 5.12397 3.08504C4.08491 5.16585 3.5 7.51353 3.5 9.99998C3.5 12.4864 4.08491 14.8341 5.12397 16.9149C5.30901 17.2855 5.15861 17.7359 4.78803 17.921C4.41745 18.106 3.96703 17.9556 3.78198 17.585C2.64135 15.3008 2 12.7242 2 9.99998C2 7.27574 2.64135 4.69914 3.78198 2.41492C3.96703 2.04434 4.41745 1.89394 4.78803 2.07899ZM7.07277 5.63341C7.86037 5.10835 8.93143 5.43509 9.29184 6.31036L10.1483 8.39026L11.2942 6.61927C11.9758 5.56586 13.3873 5.27349 14.4313 5.96947L14.666 6.12594C15.0107 6.35571 15.1038 6.82136 14.874 7.16601C14.6443 7.51065 14.1786 7.60378 13.834 7.37402L13.5993 7.21755C13.2513 6.98555 12.7808 7.08301 12.5536 7.43414L10.8431 10.0776L12.0952 13.1185L12.834 12.6259C13.1786 12.3962 13.6443 12.4893 13.874 12.834C14.1038 13.1786 14.0107 13.6443 13.666 13.874L12.9272 14.3666C12.1396 14.8916 11.0686 14.5649 10.7082 13.6896L9.85173 11.6097L8.70579 13.3807C8.02418 14.4341 6.61265 14.7265 5.56869 14.0305L5.33397 13.874C4.98933 13.6443 4.8962 13.1786 5.12596 12.834C5.35573 12.4893 5.82138 12.3962 6.16603 12.6259L6.40074 12.7824C6.74873 13.0144 7.21923 12.917 7.44644 12.5658L9.15693 9.92233L7.90482 6.88149L7.16603 7.37402C6.82138 7.60378 6.35573 7.51065 6.12596 7.16601C5.8962 6.82136 5.98933 6.35571 6.33397 6.12594L7.07277 5.63341Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
