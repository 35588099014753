import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './landingPage';
import './App.css';
import { TermsPage } from './pages/Terms/page';
import { SuccessPage } from './pages/Success/page';

function App() {
  return (
    <Router className="App">
      <div>
        <Routes>
          {/* <Route path="/about" element={<AboutPage />} /> */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/success" element={<SuccessPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
