/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniMap = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-map ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M8.15686 2.17562C7.78961 2.02365 7.37706 2.02365 7.00981 2.17562L2.92647 3.86528C2.3657 4.09732 2 4.64441 2 5.2513V16.128C2 17.1967 3.08594 17.9226 4.07353 17.514L7.58333 16.0616L11.8431 17.8243C12.2104 17.9763 12.6229 17.9763 12.9902 17.8243L17.0735 16.1347C17.6343 15.9026 18 15.3555 18 14.7486V3.87199C18 2.8032 16.9141 2.07731 15.9265 2.48596L12.4167 3.9383L8.15686 2.17562ZM7.58008 5C7.99429 5 8.33008 5.33579 8.33008 5.75V12.25C8.33008 12.6642 7.99429 13 7.58008 13C7.16586 13 6.83008 12.6642 6.83008 12.25V5.75C6.83008 5.33579 7.16586 5 7.58008 5ZM13.1699 7.75C13.1699 7.33579 12.8341 7 12.4199 7C12.0057 7 11.6699 7.33579 11.6699 7.75V14.25C11.6699 14.6642 12.0057 15 12.4199 15C12.8341 15 13.1699 14.6642 13.1699 14.25V7.75Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
