/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniScale = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-scale ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M9.99998 2C10.4142 2 10.75 2.33579 10.75 2.75V3.00836C12.2309 3.0414 13.6881 3.17204 15.1153 3.39419C15.8921 3.51509 16.66 3.6631 17.4179 3.83721C17.8216 3.92994 18.0737 4.33239 17.9809 4.73608C17.8882 5.13978 17.4858 5.39187 17.0821 5.29913C16.7168 5.21521 16.349 5.13764 15.9789 5.06655L17.6814 12.6123C17.7565 12.9452 17.5974 13.2868 17.2943 13.4435C16.6063 13.7994 15.8255 14 15 14C14.1745 14 13.3936 13.7994 12.7057 13.4435C12.4026 13.2868 12.2435 12.9452 12.3186 12.6123L14.0895 4.76296C12.9932 4.62087 11.8791 4.53512 10.75 4.50875V16.0138C12.0445 16.0616 13.3082 16.2324 14.5295 16.5153C14.933 16.6087 15.1844 17.0116 15.091 17.4151C14.9975 17.8187 14.5946 18.07 14.1911 17.9766C12.8452 17.6649 11.4423 17.5 9.99998 17.5C8.55766 17.5 7.1548 17.6649 5.80887 17.9766C5.40533 18.07 5.00245 17.8187 4.909 17.4151C4.81555 17.0116 5.06693 16.6087 5.47046 16.5153C6.69177 16.2324 7.95547 16.0616 9.24998 16.0138V4.50875C8.1209 4.53512 7.00673 4.62087 5.91045 4.76296L7.68136 12.6123C7.75646 12.9452 7.5974 13.2868 7.29431 13.4435C6.60634 13.7994 5.82549 14 4.99998 14C4.17447 14 3.39363 13.7994 2.70566 13.4435C2.40257 13.2868 2.2435 12.9452 2.3186 12.6123L4.02102 5.06655C3.65096 5.13764 3.28321 5.21521 2.9179 5.29913C2.5142 5.39187 2.11176 5.13978 2.01902 4.73608C1.92628 4.33239 2.17837 3.92994 2.58207 3.83721C3.34 3.6631 4.10785 3.51509 4.88463 3.39419C6.31186 3.17204 7.76906 3.0414 9.24998 3.00836V2.75C9.24998 2.33579 9.58577 2 9.99998 2ZM4.99998 7.54309L3.91991 12.3304C4.25956 12.4404 4.62235 12.5 4.99998 12.5C5.37761 12.5 5.74041 12.4404 6.08006 12.3304L4.99998 7.54309ZM15 7.5431L13.9199 12.3304C14.2596 12.4404 14.6224 12.5 15 12.5C15.3776 12.5 15.7404 12.4404 16.0801 12.3304L15 7.5431Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
