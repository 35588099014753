/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsSolidFaceFrown = ({ className }) => {
  return (
    <svg
      className={`heroicons-solid-face-frown ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25ZM9.375 8.25C8.83433 8.25 8.54674 8.66881 8.43901 8.88426C8.30886 9.14457 8.25 9.45171 8.25 9.75C8.25 10.0483 8.30886 10.3554 8.43901 10.6157C8.54674 10.8312 8.83433 11.25 9.375 11.25C9.91567 11.25 10.2033 10.8312 10.311 10.6157C10.4411 10.3554 10.5 10.0483 10.5 9.75C10.5 9.45171 10.4411 9.14457 10.311 8.88426C10.2033 8.66881 9.91567 8.25 9.375 8.25ZM13.689 8.88426C13.7967 8.66881 14.0843 8.25 14.625 8.25C15.1657 8.25 15.4533 8.66881 15.561 8.88426C15.6911 9.14457 15.75 9.45171 15.75 9.75C15.75 10.0483 15.6911 10.3554 15.561 10.6157C15.4533 10.8312 15.1657 11.25 14.625 11.25C14.0843 11.25 13.7967 10.8312 13.689 10.6157C13.5589 10.3554 13.5 10.0483 13.5 9.75C13.5 9.45171 13.5589 9.14457 13.689 8.88426ZM9.34869 16.8482C9.0558 17.1411 8.58092 17.1411 8.28803 16.8482C7.99514 16.5553 7.99514 16.0805 8.28803 15.7876C9.31761 14.758 10.6699 14.2453 12.0184 14.2499C13.3548 14.2545 14.6923 14.7672 15.7126 15.7876C16.0055 16.0805 16.0055 16.5553 15.7126 16.8482C15.4198 17.1411 14.9449 17.1411 14.652 16.8482C13.9229 16.1191 12.9698 15.7532 12.0132 15.7499C11.048 15.7466 10.0843 16.1126 9.34869 16.8482Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
