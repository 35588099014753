import logo from '../../images/logo_full.svg';
import logoWhite from '../../images/logo_full_white.svg';

export const Logo = ({ theme }) => {
  return (
    <div className="logo-container">
      <img src={logoWhite} alt="Spectate" className="logoWhite" /> 
      <img src={logo} alt="Spectate" className="logo" />
    </div>
  );
}