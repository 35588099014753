/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsOutlineArrowPath = ({ className }) => {
  return (
    <svg
      className={`heroicons-outline-arrow-path ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16.0228 9.34838H21.0154V9.3466M2.98413 19.6444V14.6517M2.98413 14.6517L7.97677 14.6517M2.98413 14.6517L6.16502 17.8347C7.15555 18.827 8.41261 19.58 9.86436 19.969C14.2654 21.1482 18.7892 18.5364 19.9685 14.1353M4.03073 9.86481C5.21 5.46371 9.73377 2.85191 14.1349 4.03118C15.5866 4.42017 16.8437 5.17309 17.8342 6.16547L21.0154 9.3466M21.0154 4.35577V9.3466"
        stroke="#7041F1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
