export const ptBR = {
  index: {
    header: {
      languageSelect: {
        alt: "Selecionar idioma",
        "en-US": "Inglês",
        "pt-BR": "Português",
        "es-ES": "Espanhol"
      }
    },
    main: {
      title: "A rede social<1/> focada em <0>e-Sports</0>.",
      subtitle: "O seu centro de conexões profissionais no mundo dos <1>e-Sports</1>.",
      contact: {
        beforeBr: "Qualquer dúvida entrar em contato",
        afterBr: "através do e-mail"
      },
      discord: "Entre no nosso"
    },
    form: {
      callToAction: "Faça o pré-cadastro para participar dessa revolução no cenário de jogos eletrônicos.",
      title: "Sobre você",
      name: {
        title: "Nome",
        placeholder: "Nome Sobrenome"
      },
      email: {
        title: "E-mail",
        placeholder: "email@exemplo.com"
      },
      birthDate: {
        title: "Data de nascimento",
        placeholder: "DD/MM/AAAA"
      },
      acceptTerms: "Aceito os <1>termos de uso</1>",
      acceptCommunication: "Aceito receber comunicação e newsletter via e-mail",
      submitButton: "Pré-cadastrar"
    },
    popup: {
      nameError: "Por favor, forneça seu nome para continuar.",
      emailError: "Por favor, forneça um endereço de e-mail válido para continuar.",
      birthDateError: "Por favor, forneça uma data de nascimento válida para continuar.",
      termsError: "Por favor, aceite os termos de uso para continuar.",
      updateSuccess: "Suas informações foram atualizadas com sucesso!",
      successLink: "Junte-se a nosso Discord!",
      successMessage: "Seu pré-cadastro foi concluído com sucesso.",
      closeButton: "Fechar"
    },
    loading: 'Carregando...'
  },
  terms: {
    use: {
      title: "Termos de Uso - Spectate.pro",
      list: [
        {
          title: "Aceitação dos Termos",
          description: "Ao se inscrever na plataforma <1>Spectate.pro</1>, você concorda com todos os termos de uso aqui estabelecidos.",
          link: "https://spectate.pro"
        },
        {
          title: "Coleta de Dados",
          description: "Coletamos informações pessoais como nome, e-mail, data de nascimento e dados de navegação para fornecer uma experiência personalizada e segura."
        },
        {
          title: "Uso dos Dados",
          description: "Usamos seus dados para:",
          list: [
            "Melhorar nossos serviços",
            "Oferecer conteúdo relevante",
            "Enviar comunicações importantes",
            "Analisar e entender o uso da plataforma"
          ]
        },
        {
          title: "Armazenamento de Dados",
          description: "Seus dados são armazenados de forma segura em nossos servidores, com medidas rigorosas de proteção contra acesso não autorizado, uso indevido e divulgação."
        },
        {
          title: "Compartilhamento de Dados",
          description: "Ao se inscrever na Podemos compartilhar seus dados com parceiros confiáveis para aprimorar os serviços oferecidos, sempre respeitando sua privacidade e a legislação vigente. Spectate.pro, você concorda com todos os termos de uso aqui estabelecidos."
        },
        {
          title: "Direitos do Usuário",
          description: "Você tem o direito de acessar, corrigir e excluir suas informações pessoais a qualquer momento. Pode também revogar seu consentimento para o uso de dados, conforme previsto na Lei Geral de Proteção de Dados (LGPD)."
        },
        {
          title: "Consentimento",
          description: "Ao se inscrever na <1>Spectate.pro</1>, você consente com a coleta, uso, armazenamento e compartilhamento de seus dados conforme descrito nestes termos.",
          link: "https://spectate.pro"
        },
        {
          title: "Alterações nos Termos",
          description: "Podemos atualizar estes termos periodicamente. Notificaremos sobre mudanças significativas através de nossa plataforma ou por e-mail."
        },
        {
          title: "Contato",
          description: "Para dúvidas ou solicitações sobre seus dados, entre em contato conosco pelo <1>contact@spectate.pro</1>.",
          link: "mailto:contact@spectate.pro"
        },
      ]
    },
    privacy: {
      title: "Política de Privacidade",
      list: [
        {
          title: "Introdução",
          description: "A <1>Spectate.pro</1> respeita sua privacidade e se compromete a proteger suas informações pessoais.",
          link: "https://spectate.pro"
        },
        {
          title: "Dados Coletados",
          description: "Coletamos dados que você fornece diretamente e informações automaticamente geradas pelo uso da plataforma.",
        },
        {
          title: "Finalidade do Tratamento de Dados",
          description: "Os dados são tratados para fornecer e melhorar nossos serviços, comunicar com você e cumprir obrigações legais.",
        },
        {
          title: "Segurança de Dados",
          description: "Adotamos medidas técnicas e administrativas para proteger seus dados pessoais.",
        },
        {
          title: "Compartilhamento de Dados",
          description: "Compartilhamos dados com terceiros apenas quando necessário para operar e melhorar nossos serviços ou conforme exigido por lei.",
        },
        {
          title: "Seus Direitos",
          description: "Você tem direito de acessar, corrigir, excluir, e solicitar a portabilidade dos seus dados, além de revogar o consentimento a qualquer momento.",
        },
        {
          title: "Mudanças na Política",
          description: "Podemos revisar esta política periodicamente. Informaremos sobre mudanças significativas.",
        },
        {
          title: "Contato",
          description: "Para qualquer questão relacionada à privacidade, entre em contato pelo <1>contact@spectate.pro</1>.",
          link: "mailto:contact@spectate.pro"
        },
      ]
    },
    agree: "Ao utilizar a <1>Spectate.pro</1>, você concorda com esses termos e políticas."
  }
}