import PropTypes from "prop-types";
import React, { useState, useEffect, forwardRef, useRef } from "react";
import { HeroiconsSolidInformationCircle } from "../../icons/HeroiconsSolidInformationCircle";
//import { HeroiconsOutlineUser } from "../../icons/HeroiconsOutlineUser";
import "./style.css";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import i18n from '../../i18n';
import { getDatePickerLocale, getDayjsLocale } from '../../locales';
import 'dayjs/locale/pt-br'
import 'dayjs/locale/es'
import dayjs from 'dayjs';

export const InputBasic = forwardRef(({
  icon_class,
  icon_color,
  icon_extra_class,
  iconRight = false,
  suffix = true,
  iconLeft = true,
  suffixText,
  prefixText,
  placeholderText = "Input placeholder",
  prefix = true,
  icon_type = 'outlined',
  icon,
  icon_spec,
  value,
  text,
  size,
  type,
  state,
  name,
  onChange,
  onKeyPress,
  onFocus,
  onBlur,
  // Add any other props you need HeroiconsOutlineUser
}, ref) => {

  const [IconComponent, setIconComponent] = useState(null);
  const [currentLocale, setCurrentLocale] = useState(i18n.language);
  const staticIcons = {
 // "HeroiconsOutlineUser": HeroiconsOutlineUser,
  };

   useEffect(() => {
    if (icon_class) {
      // Dynamic import from the index file
      import(`../../icons`)
        .then((icons) => {
          const IconComponent = icons[icon_class];
          if (IconComponent) {
            setIconComponent(() => IconComponent);
          } else {
            console.error(`Icon not found: ${icon_class}`);
          }
        })
        .catch(err => {
          console.error('Dynamic icon loading failed', err);
        });
    }
  }, [icon_class]);

  useEffect(() => {
    setCurrentLocale(i18n.language);
  }, [i18n.language]);

  const getDateYearsBehind = (years) => {
    const date = new Date();
    const pastYear = date.getFullYear() - years;
    date.setFullYear(pastYear);
    return date
  }

  const handleDateChange = (newValue, ctx) => {
    handleDateInputStyle(newValue)
    onChange(newValue, ctx)
  }

  const handleDateInputStyle = (newValue) => {
    if (ref?.current) {
      if (!newValue || ref.current.placeholder === newValue) {
        ref.current.classList.add('date-placeholder-shown')
      } else {
        ref.current.classList.remove('date-placeholder-shown')
      }
    }
  }

  useEffect(() => {
    handleDateInputStyle(value)
    if (ref?.current) {
      ref.current.addEventListener('input', () => handleDateInputStyle(ref.current.value))
    }
  }, [])

    return (
    <div>
      <label className={`input-basic ${size} ${state}`} htmlFor={name}>
        {prefix && <div className="prefix">{prefixText}</div>}

        {iconLeft && IconComponent && (
          <>
            <>
              {["default", "small"].includes(size) && (
                <div>
                  <IconComponent 
                    className={icon_extra_class}
                    stroke={icon_color}
                    color={state === "disabled" ? "#626E8D" : "#7041F1"}

                  />
                </div>
              )}
              {size === "large" && (
                <div>
                  <IconComponent
                    className={icon_extra_class}
                    stroke={icon_color}
                    color={state === "disabled" ? "#626E8D" : "#7041F1"}
                  />
                </div>
              )}
            </>
          </>
        )}

        {type === 'date' ? (
          <LocalizationProvider
           dateAdapter={AdapterDayjs}
           adapterLocale={getDayjsLocale(currentLocale)}
           localeText={getDatePickerLocale(currentLocale)}
          >
          <DatePicker
            sx={{
              width: '100%',
              height: '20px',
              marginLeft: '5px',
              '& .MuiInputBase-root': {
                paddingRight: '2px',
              },
              '& .MuiInputBase-input': {
                padding: '0',
                fontSize: '.9rem',
                verticalAlign: 'center'
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '0px !important',
              },
              '& .MuiSvgIcon-root': {
                fill: '#7041F1',
                width: '20px',
              },
            }}
            minDate={dayjs(getDateYearsBehind(150))}
            maxDate={dayjs(getDateYearsBehind(13))}
            dayOfWeekFormatter={date => new AdapterDayjs().format(date, 'weekday').charAt(0).toUpperCase()}
            onChange={handleDateChange}
            inputRef={ref}
            value={dayjs(value)}
          />
          </LocalizationProvider>
        ) : (
          <input
            type={text} // or any other type you need
            id={name}
            name={name}
            value={value}
            placeholder={placeholderText}
            ref={ref}
            onChange={onChange}
            onKeyPress={onKeyPress}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        )}
        

        {iconRight && (
          <HeroiconsSolidInformationCircle 
            className={icon_extra_class}
          />
        )}

        {suffix && <div className="suffix">{suffixText}</div>}
      </label>
    </div>
  );
});

InputBasic.propTypes = {
  icon_class: PropTypes.string,
  iconRight: PropTypes.bool,
  suffix: PropTypes.bool,
  iconLeft: PropTypes.bool,
  suffixText: PropTypes.string,
  prefixText: PropTypes.string,
  placeholderText: PropTypes.string,
  prefix: PropTypes.bool,
  size: PropTypes.oneOf(["large", "small", "default"]),
  state: PropTypes.oneOf(["default", "focused", "filled", "hover", "typing", "disabled"]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  text: PropTypes.string,
  icon_type: PropTypes.string,
  icon: PropTypes.string,
};
