/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniArrowTrendingUp = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-arrow-trending-up ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M12.577 4.87833C12.6842 4.47823 13.0955 4.24079 13.4956 4.348L18.2766 5.62909C18.4688 5.68057 18.6326 5.80627 18.732 5.97853C18.8315 6.1508 18.8585 6.35551 18.807 6.54765L17.5259 11.3287C17.4187 11.7288 17.0074 11.9663 16.6073 11.8591C16.2072 11.7519 15.9698 11.3406 16.077 10.9405L16.8865 7.91949C14.6303 9.30965 12.7541 11.0901 11.2935 13.1222C11.1651 13.3009 10.9646 13.4142 10.7452 13.432C10.5259 13.4498 10.3098 13.3704 10.1542 13.2148L7 10.0607L2.28033 14.7803C1.98744 15.0732 1.51256 15.0732 1.21967 14.7803C0.926777 14.4874 0.926777 14.0126 1.21967 13.7197L6.46967 8.46967C6.76256 8.17678 7.23744 8.17678 7.53033 8.46967L10.6039 11.5433C12.1049 9.6305 13.9633 7.95059 16.1492 6.61196L13.1073 5.79689C12.7072 5.68968 12.4698 5.27843 12.577 4.87833Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
