/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniBellAlert = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-bell-alert ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M4.21444 3.22682C4.47824 2.90747 4.43321 2.43474 4.11386 2.17094C3.79452 1.90714 3.32178 1.95217 3.05798 2.27151C2.1587 3.36014 1.50992 4.66493 1.20153 6.09703C1.11433 6.50196 1.37191 6.90091 1.77684 6.98811C2.18177 7.07531 2.58072 6.81774 2.66792 6.41281C2.92443 5.22161 3.46427 4.13494 4.21444 3.22682Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M16.9417 2.27151C16.6779 1.95217 16.2051 1.90714 15.8858 2.17094C15.5664 2.43474 15.5214 2.90747 15.7852 3.22682C16.5354 4.13494 17.0752 5.22161 17.3317 6.41281C17.4189 6.81774 17.8179 7.07531 18.2228 6.98811C18.6277 6.90091 18.8853 6.50196 18.7981 6.09703C18.4897 4.66493 17.8409 3.36014 16.9417 2.27151Z"
        fill="#7041F1"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M9.99997 2C6.68626 2 3.99997 4.68629 3.99997 8C3.99997 9.88663 3.54624 11.6651 2.7426 13.2343C2.63591 13.4426 2.6326 13.6888 2.73365 13.9C2.83469 14.1111 3.02851 14.2629 3.25769 14.3105C4.32537 14.5322 5.41181 14.7023 6.51426 14.818C6.67494 16.602 8.17421 18 10 18C11.8258 18 13.3251 16.602 13.4857 14.818C14.5882 14.7023 15.6746 14.5322 16.7422 14.3105C16.9714 14.2629 17.1652 14.1111 17.2663 13.9C17.3673 13.6888 17.364 13.4426 17.2573 13.2343C16.4537 11.6651 16 9.88663 16 8C16 4.68629 13.3137 2 9.99997 2ZM10 16.5C9.04777 16.5 8.25097 15.8345 8.0493 14.9433C8.69477 14.9809 9.34517 15 9.99997 15C10.6548 15 11.3052 14.9809 11.9507 14.9433C11.749 15.8345 10.9522 16.5 10 16.5Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
