/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const CircleOutline = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-adjustments-horizontal ${className}`}
      fill="none"
      height="44"
      viewBox="0 0 44 44"
      width="44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22" cy="22" r="21.5" stroke="#7041F1" className='path'/>
    </svg>
  );
};
