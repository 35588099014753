/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsOutlineCog8Tooth = ({ className }) => {
  return (
    <svg
      className={`heroicons-outline-cog-8-tooth ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M10.3426 3.94005C10.433 3.39759 10.9024 3 11.4523 3H12.5463C13.0962 3 13.5656 3.39759 13.656 3.94005L13.8049 4.83386C13.8757 5.25813 14.1886 5.59838 14.5858 5.76332C14.9832 5.92832 15.4396 5.90629 15.7897 5.65617L16.5273 5.12933C16.9748 4.80969 17.5878 4.86042 17.9767 5.24929L18.7503 6.02284C19.1391 6.41171 19.1899 7.02472 18.8702 7.47223L18.3432 8.21007C18.0931 8.56012 18.0711 9.01633 18.236 9.41363C18.4009 9.81078 18.7411 10.1236 19.1653 10.1943L20.0592 10.3433C20.6017 10.4337 20.9993 10.9031 20.9993 11.453V12.547C20.9993 13.0969 20.6017 13.5663 20.0592 13.6567L19.1654 13.8056C18.7412 13.8764 18.4009 14.1893 18.236 14.5865C18.071 14.9839 18.093 15.4403 18.3431 15.7904L18.8699 16.5278C19.1895 16.9753 19.1388 17.5884 18.7499 17.9772L17.9764 18.7508C17.5875 19.1396 16.9745 19.1904 16.527 18.8707L15.7894 18.3439C15.4393 18.0938 14.983 18.0718 14.5857 18.2367C14.1885 18.4016 13.8757 18.7418 13.805 19.166L13.656 20.0599C13.5656 20.6024 13.0962 21 12.5463 21H11.4523C10.9024 21 10.433 20.6024 10.3426 20.0599L10.1937 19.1661C10.1229 18.7419 9.81002 18.4016 9.41278 18.2367C9.01538 18.0717 8.55905 18.0937 8.2089 18.3438L7.47128 18.8707C7.02377 19.1904 6.41076 19.1396 6.02189 18.7507L5.24834 17.9772C4.85947 17.5883 4.80874 16.9753 5.12838 16.5278L5.65542 15.79C5.90546 15.4399 5.9275 14.9837 5.76255 14.5864C5.59767 14.1892 5.25749 13.8764 4.83332 13.8057L3.93935 13.6567C3.39689 13.5663 2.9993 13.0969 2.9993 12.547V11.453C2.9993 10.9031 3.39689 10.4337 3.93935 10.3433L4.83316 10.1944C5.25743 10.1236 5.59768 9.81071 5.76262 9.41347C5.92763 9.01605 5.90559 8.5597 5.65547 8.20954L5.12878 7.47216C4.80913 7.02465 4.85986 6.41164 5.24873 6.02277L6.02228 5.24922C6.41115 4.86036 7.02416 4.80962 7.47167 5.12927L8.20927 5.65613C8.55934 5.90618 9.01558 5.92822 9.4129 5.76326C9.81007 5.59837 10.1229 5.25819 10.1936 4.834L10.3426 3.94005Z"
        stroke="#7041F1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3432 10.3431 9.00002 12 9.00002C13.6569 9.00002 15 10.3432 15 12Z"
        stroke="#7041F1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
