/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniPrinter = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-printer ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M5 2.75C5 1.7835 5.7835 1 6.75 1H13.25C14.2165 1 15 1.7835 15 2.75V6.25C15 6.26733 14.9997 6.28459 14.9992 6.3018C15.3765 6.34767 15.7522 6.39867 16.1263 6.45472C17.2246 6.6193 18 7.57138 18 8.65297V12.75C18 13.9926 16.9926 15 15.75 15H15.5086L15.8138 16.9839C15.9769 18.044 15.1567 19 14.0842 19H5.91541C4.84287 19 4.02268 18.044 4.18576 16.9839L4.49098 15H4.25C3.00736 15 2 13.9926 2 12.75V8.65298C2 7.57138 2.77538 6.6193 3.87368 6.45472C4.24776 6.39867 4.62347 6.34767 5.00075 6.3018C5.00025 6.28459 5 6.26733 5 6.25V2.75ZM13.5 6.14734C12.3461 6.04977 11.1788 6 10 6C8.82119 6 7.65386 6.04977 6.5 6.14734V2.75C6.5 2.61193 6.61193 2.5 6.75 2.5H13.25C13.3881 2.5 13.5 2.61193 13.5 2.75V6.14734ZM6.60772 12.5C6.48433 12.5 6.37939 12.59 6.36063 12.712L5.66832 17.212C5.64502 17.3634 5.76219 17.5 5.91541 17.5H14.0842C14.2374 17.5 14.3546 17.3634 14.3313 17.212L13.639 12.712C13.6202 12.59 13.5153 12.5 13.3919 12.5H6.60772Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
