/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniMusicalNote = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-musical-note ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M17.721 1.59874C17.8974 1.74112 18 1.95569 18 2.18243V13.4721C18 14.5315 17.261 15.4474 16.2255 15.6713L14.1848 16.1125C12.804 16.4111 11.5 15.3589 11.5 13.9462C11.5 12.903 12.2275 12.001 13.247 11.7801L15.9088 11.2033C16.2538 11.1285 16.5 10.8233 16.5 10.4703V6.11192L8.5 7.84165V15.5261C8.5 16.5855 7.76097 17.5014 6.7255 17.7253L4.68397 18.1667C3.30357 18.4652 2 17.4134 2 16.0011C2 14.9572 2.72854 14.055 3.74894 13.8351L6.408 13.262C6.7534 13.1876 7 12.8822 7 12.5289V4.23648C7 3.88334 7.24634 3.57805 7.5915 3.50342L17.0915 1.44936C17.3131 1.40145 17.5445 1.45636 17.721 1.59874Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
