/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniBeaker = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-beaker ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M8.50002 3.52795V8.17157C8.50002 8.90092 8.21029 9.60039 7.69456 10.1161L6.47815 11.3325C7.68607 11.2911 8.89518 11.5003 10.0275 11.9532L10.5296 12.1541C11.8561 12.6847 13.3098 12.8115 14.7081 12.5187L12.3055 10.1161C11.7898 9.60039 11.5 8.90092 11.5 8.17157V3.52795C11.0024 3.50937 10.5023 3.5 10 3.5C9.49774 3.5 8.99769 3.50937 8.50002 3.52795ZM13 3.61218C13.0635 3.61695 13.1269 3.62186 13.1903 3.62693C13.6032 3.65992 13.9646 3.35194 13.9976 2.93905C14.0306 2.52615 13.7227 2.16468 13.3098 2.13169C12.9714 2.10466 12.6319 2.08173 12.2913 2.06296C11.5328 2.02117 10.7688 2 10 2C9.23121 2 8.46727 2.02117 7.70876 2.06296C7.36815 2.08173 7.02865 2.10466 6.69028 2.13169C6.27739 2.16468 5.96941 2.52615 6.0024 2.93905C6.03539 3.35194 6.39686 3.65992 6.80976 3.62693C6.87314 3.62186 6.93656 3.61695 7.00002 3.61218V8.17157C7.00002 8.50309 6.86832 8.82104 6.6339 9.05546L2.60037 13.089C1.10388 14.5855 1.78337 17.2391 4.00339 17.5645C5.96113 17.8515 7.96346 18 10 18C12.0366 18 14.0389 17.8515 15.9967 17.5645C18.2167 17.2391 18.8962 14.5855 17.3997 13.089L13.3661 9.05546C13.1317 8.82104 13 8.50309 13 8.17157V3.61218Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
