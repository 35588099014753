/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsOutlineCog6Tooth = ({ className }) => {
  return (
    <svg
      className={`heroicons-outline-cog-6-tooth ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M9.59356 3.94017C9.68397 3.39771 10.1533 3.00012 10.7033 3.00012H13.2972C13.8472 3.00012 14.3165 3.39771 14.4069 3.94017L14.6204 5.22122C14.6828 5.59527 14.9327 5.90683 15.2645 6.09048C15.3387 6.13154 15.412 6.17396 15.4844 6.21769C15.8095 6.41396 16.2048 6.47499 16.5604 6.34178L17.7772 5.8859C18.2922 5.69297 18.8712 5.90063 19.1462 6.3769L20.4432 8.62333C20.7181 9.0996 20.6085 9.70485 20.1839 10.0544L19.1795 10.8812C18.887 11.122 18.742 11.4938 18.7491 11.8727C18.7498 11.915 18.7502 11.9575 18.7502 12.0001C18.7502 12.0427 18.7498 12.0852 18.7491 12.1276C18.742 12.5064 18.887 12.8782 19.1795 13.119L20.1839 13.9458C20.6085 14.2954 20.7181 14.9006 20.4432 15.3769L19.1462 17.6233C18.8712 18.0996 18.2922 18.3073 17.7772 18.1143L16.5604 17.6585C16.2048 17.5252 15.8095 17.5863 15.4844 17.7825C15.412 17.8263 15.3387 17.8687 15.2645 17.9098C14.9327 18.0934 14.6828 18.405 14.6204 18.779L14.4069 20.0601C14.3165 20.6025 13.8472 21.0001 13.2972 21.0001H10.7033C10.1533 21.0001 9.68397 20.6025 9.59356 20.0601L9.38005 18.779C9.31771 18.405 9.06774 18.0934 8.73597 17.9098C8.66179 17.8687 8.58847 17.8263 8.51604 17.7826C8.19101 17.5863 7.79568 17.5253 7.44011 17.6585L6.22325 18.1143C5.70826 18.3073 5.12926 18.0996 4.85429 17.6233L3.55731 15.3769C3.28234 14.9006 3.39199 14.2954 3.81657 13.9459L4.82092 13.119C5.11343 12.8782 5.25843 12.5064 5.25141 12.1276C5.25063 12.0852 5.25023 12.0427 5.25023 12.0001C5.25023 11.9575 5.25063 11.9151 5.25141 11.8727C5.25843 11.4939 5.11343 11.122 4.82092 10.8812L3.81657 10.0544C3.39199 9.70487 3.28234 9.09961 3.55731 8.62335L4.85429 6.37691C5.12926 5.90065 5.70825 5.69298 6.22325 5.88591L7.4401 6.34179C7.79566 6.475 8.19099 6.41397 8.51603 6.2177C8.58846 6.17396 8.66179 6.13154 8.73597 6.09048C9.06774 5.90683 9.31771 5.59527 9.38005 5.22122L9.59356 3.94017Z"
        stroke="#7041F1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3432 10.3431 9.00004 12 9.00004C13.6569 9.00004 15 10.3432 15 12Z"
        stroke="#7041F1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
