/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniUserMinus = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-user-minus ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M11 5C11 6.65685 9.65685 8 8 8C6.34315 8 5 6.65685 5 5C5 3.34315 6.34315 2 8 2C9.65685 2 11 3.34315 11 5Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M2.04605 15.2529C1.98785 15.721 2.21798 16.1736 2.61528 16.428C4.16959 17.4231 6.01737 18 7.9999 18C9.98243 18 11.8302 17.4225 13.3845 16.4273C13.7818 16.1729 14.012 15.7203 13.9537 15.2522C13.5856 12.2914 11.0604 10 7.9999 10C4.93944 10 2.41416 12.292 2.04605 15.2529Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M12.75 7.75C12.3358 7.75 12 8.08579 12 8.5C12 8.91421 12.3358 9.25 12.75 9.25H18.25C18.6642 9.25 19 8.91421 19 8.5C19 8.08579 18.6642 7.75 18.25 7.75H12.75Z"
        fill="#7041F1"
      />
    </svg>
  );
};
