import { enUS, ptBR, esES } from '@mui/x-date-pickers/locales'

export const getDatePickerLocale = (locale) => {
  const locales = {
    "en-US": enUS.components.MuiLocalizationProvider.defaultProps.localeText,
    "pt-BR": ptBR.components.MuiLocalizationProvider.defaultProps.localeText,
    "es-MX": esES.components.MuiLocalizationProvider.defaultProps.localeText
  }
  return locales[locale]
}

export const getDayjsLocale = (locale) => {
  const locales = {
    "en-US": "en",
    "pt-BR": "pt-br",
    "es-MX": "es"
  }
  return locales[locale]
}