/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniSignalSlash = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-signal-slash ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M2.21967 2.21967C2.51256 1.92678 2.98744 1.92678 3.28033 2.21967L10.0626 9.00193C10.565 9.03295 10.967 9.43498 10.9981 9.93741L17.7803 16.7197C18.0732 17.0126 18.0732 17.4874 17.7803 17.7803C17.4874 18.0732 17.0126 18.0732 16.7197 17.7803L9.93741 10.9981C9.43498 10.967 9.03295 10.565 9.00193 10.0626L2.21967 3.28033C1.92678 2.98744 1.92678 2.51256 2.21967 2.21967Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M3.63604 16.364C0.670686 13.3986 0.207189 8.87882 2.24555 5.42753L3.3485 6.53048C1.87267 9.35787 2.32207 12.9287 4.6967 15.3033C4.98959 15.5962 4.98959 16.0711 4.6967 16.364C4.40381 16.6569 3.92893 16.6569 3.63604 16.364Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M6.46447 13.5356C5.08411 12.1552 4.67956 10.1685 5.25082 8.43281L6.51322 9.6952C6.4268 10.6896 6.76411 11.7139 7.52513 12.4749C7.81802 12.7678 7.81802 13.2427 7.52513 13.5356C7.23223 13.8284 6.75736 13.8284 6.46447 13.5356Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M16.364 3.63605C19.3293 6.6014 19.7928 11.1212 17.7545 14.5725L16.6515 13.4695C18.1273 10.6421 17.6779 7.07134 15.3033 4.69671C15.0104 4.40382 15.0104 3.92895 15.3033 3.63605C15.5962 3.34316 16.0711 3.34316 16.364 3.63605Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M13.5355 6.46449C14.9159 7.84484 15.3204 9.83147 14.7492 11.5672L13.4868 10.3048C13.5732 9.31043 13.2359 8.28616 12.4749 7.52515C12.182 7.23225 12.182 6.75738 12.4749 6.46449C12.7678 6.17159 13.2426 6.17159 13.5355 6.46449Z"
        fill="#7041F1"
      />
    </svg>
  );
};
