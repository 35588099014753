import { enUS } from './en-US'
import { esMX } from './es-MX'
import { ptBR } from './pt-BR'

export const translations = {
  "en-US": enUS,
  "pt-BR": ptBR,
  "es-MX": esMX
}

export * from './helpers'