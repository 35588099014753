/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniArrowLongUp = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-arrow-long-up ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M10 18C9.58579 18 9.25 17.6642 9.25 17.25L9.25 4.6599L7.2996 6.76034C7.01775 7.06387 6.5432 7.08145 6.23966 6.7996C5.93613 6.51774 5.91856 6.0432 6.20041 5.73966L9.45041 2.23966C9.59232 2.08684 9.79145 2 10 2C10.2086 2 10.4077 2.08684 10.5496 2.23966L13.7996 5.73966C14.0815 6.04319 14.0639 6.51774 13.7603 6.79959C13.4568 7.08145 12.9823 7.06387 12.7004 6.76034L10.75 4.6599L10.75 17.25C10.75 17.6642 10.4142 18 10 18Z"
        fill="#7041F1"
        fillRule="evenodd"
      />
    </svg>
  );
};
