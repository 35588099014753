/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsMiniSpeakerXMark = ({ className }) => {
  return (
    <svg
      className={`heroicons-mini-speaker-x-mark ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M9.54747 3.0615C9.82215 3.18018 10 3.45077 10 3.74999V16.25C10 16.5492 9.82215 16.8198 9.54747 16.9385C9.2728 17.0571 8.95387 17.0012 8.73598 16.7961L4.70257 13H3.16724C2.85725 13 2.5792 12.8093 2.46756 12.5201C2.16534 11.7371 2 10.887 2 9.99999C2 9.11299 2.16534 8.26284 2.46756 7.4799C2.5792 7.1907 2.85725 6.99999 3.16724 6.99999H4.70257L8.73598 3.20384C8.95387 2.99876 9.2728 2.94282 9.54747 3.0615Z"
        fill="#7041F1"
      />
      <path
        className="path"
        d="M13.2803 7.21967C12.9874 6.92678 12.5126 6.92678 12.2197 7.21967C11.9268 7.51256 11.9268 7.98744 12.2197 8.28033L13.9393 10L12.2197 11.7197C11.9268 12.0126 11.9268 12.4874 12.2197 12.7803C12.5126 13.0732 12.9874 13.0732 13.2803 12.7803L15 11.0607L16.7197 12.7803C17.0126 13.0732 17.4874 13.0732 17.7803 12.7803C18.0732 12.4874 18.0732 12.0126 17.7803 11.7197L16.0607 10L17.7803 8.28033C18.0732 7.98744 18.0732 7.51256 17.7803 7.21967C17.4874 6.92678 17.0126 6.92678 16.7197 7.21967L15 8.93934L13.2803 7.21967Z"
        fill="#7041F1"
      />
    </svg>
  );
};
