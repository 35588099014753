/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IndivIConsSolidLinkedin = ({ className }) => {
  return (
     <svg
      className={`heroicons-solid-x-mark ${className}`}
      fill="none" 
      height="24"
      viewBox="0 0 14 14"
      width="24"
      xmlns="http://www.w3.org/2000/svg">
      <path className="path" clipRule="evenodd" fillRule="evenodd" fill= "none" d="M0.490909 2.73355C0.163048 2.42915 0 2.05235 0 1.60403C0 1.1557 0.16392 0.762325 0.490909 0.457046C0.818769 0.152639 1.24081 0 1.75788 0C2.27496 0 2.68043 0.152639 3.00742 0.457046C3.33528 0.761453 3.49833 1.14436 3.49833 1.60403C3.49833 2.06369 3.33441 2.42915 3.00742 2.73355C2.67956 3.03796 2.26363 3.1906 1.75788 3.1906C1.25214 3.1906 0.818769 3.03796 0.490909 2.73355ZM3.2228 4.47975V13.8126H0.274662V4.47975H3.2228Z" />
      <path className="path" clipRule="evenodd" fillRule="evenodd" fill= "none" d="M13.0367 5.40141C13.6793 6.09919 14.0002 7.0569 14.0002 8.27627V13.6474H11.2003V8.65482C11.2003 8.0399 11.0408 7.56191 10.7225 7.22174C10.4042 6.88158 9.97522 6.71062 9.43809 6.71062C8.90095 6.71062 8.47193 6.8807 8.15366 7.22174C7.83539 7.56191 7.67582 8.0399 7.67582 8.65482V13.6474H4.85938V4.4533H7.67582V5.67267C7.96095 5.26621 8.34551 4.94524 8.82858 4.70887C9.31165 4.47249 9.85489 4.35474 10.4592 4.35474C11.5352 4.35474 12.3949 4.70363 13.0367 5.40053V5.40141Z" />
    </svg>
  );
};